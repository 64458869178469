.fancybox-enabled {
  overflow: hidden;
}

.fancybox-enabled body {
  overflow: visible;
  -ms-touch-action: none;
  touch-action: none;
}

.fancybox-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99993;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

/* Make sure that the first one is on the top */
.fancybox-container ~ .fancybox-container {
  z-index: 99992;
}

.fancybox-bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #0f0f11;
  opacity: 0;
  transition-timing-function: cubic-bezier(0.55, 0.06, 0.68, 0.19);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.fancybox-container--ready .fancybox-bg {
  opacity: 0.87;
  transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1);
}

.fancybox-controls {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  opacity: 0;
  z-index: 99994;
  transition: opacity .2s;
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  direction: ltr;
}

.fancybox-show-controls .fancybox-controls {
  opacity: 1;
}

.fancybox-infobar {
  display: none;
}

.fancybox-show-infobar .fancybox-infobar {
  display: inline-block;
  pointer-events: all;
}

.fancybox-infobar__body {
  display: inline-block;
  width: 70px;
  line-height: 44px;
  font-size: 13px;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  text-align: center;
  color: #ddd;
  background-color: rgba(30, 30, 30, 0.7);
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: subpixel-antialiased;
}

.fancybox-buttons {
  position: absolute;
  top: 0;
  right: 0;
  display: none;
  pointer-events: all;
}

.fancybox-show-buttons .fancybox-buttons {
  display: block;
}

.fancybox-slider-wrap {
  overflow: hidden;
  direction: ltr;
}

.fancybox-slider-wrap,
.fancybox-slider {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
  z-index: 99993;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.fancybox-slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: auto;
  outline: none;
  white-space: normal;
  box-sizing: border-box;
  text-align: center;
  z-index: 99994;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: transparent;
}

.fancybox-slide::before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  width: 0;
}

.fancybox-slide > * {
  display: inline-block;
  position: relative;
  padding: 24px;
  margin: 44px 0 44px;
  border-width: 0;
  vertical-align: middle;
  text-align: left;
  background-color: #fff;
  overflow: auto;
  box-sizing: border-box;
}

.fancybox-slide--image {
  overflow: hidden;
}

.fancybox-slide--image::before {
  display: none;
}

.fancybox-content {
  display: inline-block;
  position: relative;
  margin: 44px auto;
  padding: 0;
  border: 0;
  width: 80%;
  height: calc(100% - 88px);
  vertical-align: middle;
  line-height: normal;
  text-align: left;
  white-space: normal;
  outline: none;
  font-size: 16px;
  font-family: Arial, sans-serif;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  -webkit-overflow-scrolling: touch;
}

.fancybox-iframe {
  display: block;
  margin: 0;
  padding: 0;
  border: 0;
  width: 100%;
  height: 100%;
  background: #fff;
}

.fancybox-slide--video .fancybox-content,
.fancybox-slide--video .fancybox-iframe {
  background: transparent;
}

.fancybox-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  border: 0;
  z-index: 99995;
  background: transparent;
  cursor: default;
  overflow: visible;
  -webkit-transform-origin: top left;
  -ms-transform-origin: top left;
  transform-origin: top left;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.fancybox-image,
.fancybox-spaceball {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  max-width: none;
  max-height: none;
  background: transparent;
  background-size: 100% 100%;
}

.fancybox-controls--canzoomOut .fancybox-placeholder {
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}

.fancybox-controls--canzoomIn .fancybox-placeholder {
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}

.fancybox-controls--canGrab .fancybox-placeholder {
  cursor: -webkit-grab;
  cursor: grab;
}

.fancybox-controls--isGrabbing .fancybox-placeholder {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.fancybox-spaceball {
  z-index: 1;
}

.fancybox-tmp {
  position: absolute;
  top: -9999px;
  left: -9999px;
  visibility: hidden;
}

.fancybox-error {
  position: absolute;
  margin: 0;
  padding: 40px;
  top: 50%;
  left: 50%;
  width: 380px;
  max-width: 100%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: #fff;
  cursor: default;
}

.fancybox-error p {
  margin: 0;
  padding: 0;
  color: #444;
  font: 16px/20px "Helvetica Neue",Helvetica,Arial,sans-serif;
}

.fancybox-close-small {
  position: absolute;
  top: 4px;
  right: 4px;
  padding: 0;
  margin: 0;
  width: 30px;
  height: 30px;
  font: 21px/1 Arial,"Helvetica Neue",Helvetica,sans-serif;
  color: #888;
  font-weight: 300;
  text-align: center;
  border-radius: 50%;
  border-width: 0;
  cursor: pointer;
  background: #fff;
  transition: background .2s;
  box-sizing: border-box;
  z-index: 2;
}

.fancybox-close-small:focus {
  outline: 1px dotted #888;
}

.fancybox-slide--video .fancybox-close-small {
  top: -36px;
  right: -36px;
  background: transparent;
}

.fancybox-close-small:hover {
  color: #555;
  background: #eee;
}

/* Caption */
.fancybox-caption-wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 60px 30px 0 30px;
  z-index: 99998;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  box-sizing: border-box;
  background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.1) 20%, rgba(0, 0, 0, 0.2) 40%, rgba(0, 0, 0, 0.6) 80%, rgba(0, 0, 0, 0.8) 100%);
  opacity: 0;
  transition: opacity .2s;
  pointer-events: none;
}

.fancybox-show-caption .fancybox-caption-wrap {
  opacity: 1;
}

.fancybox-caption {
  padding: 30px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.4);
  font-size: 14px;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  color: #fff;
  line-height: 20px;
  -webkit-text-size-adjust: none;
}

.fancybox-caption a,
.fancybox-caption button {
  pointer-events: all;
}

.fancybox-caption a {
  color: #fff;
  text-decoration: underline;
}

/* Buttons */
.fancybox-button {
  display: inline-block;
  position: relative;
  width: 44px;
  height: 44px;
  line-height: 44px;
  margin: 0;
  padding: 0;
  border: 0;
  border-radius: 0;
  cursor: pointer;
  background: transparent;
  color: #fff;
  box-sizing: border-box;
  vertical-align: top;
  outline: none;
}

.fancybox-button--disabled {
  cursor: default;
  pointer-events: none;
}

.fancybox-infobar__body, .fancybox-button {
  background: rgba(30, 30, 30, 0.6);
}

.fancybox-button:hover {
  background: rgba(0, 0, 0, 0.8);
}

.fancybox-button::before,
.fancybox-button::after {
  content: '';
  pointer-events: none;
  position: absolute;
  border-color: #fff;
  background-color: currentColor;
  color: currentColor;
  opacity: 0.9;
  box-sizing: border-box;
  display: inline-block;
}

.fancybox-button--disabled::before,
.fancybox-button--disabled::after {
  opacity: 0.5;
}

.fancybox-button--left::after {
  left: 20px;
  top: 18px;
  width: 6px;
  height: 6px;
  background: transparent;
  border-top: solid 2px currentColor;
  border-right: solid 2px currentColor;
  -webkit-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.fancybox-button--right::after {
  right: 20px;
  top: 18px;
  width: 6px;
  height: 6px;
  background: transparent;
  border-top: solid 2px currentColor;
  border-right: solid 2px currentColor;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.fancybox-button--left {
  border-bottom-left-radius: 5px;
}

.fancybox-button--right {
  border-bottom-right-radius: 5px;
}

.fancybox-button--close {
  float: right;
}

.fancybox-button--close::before, .fancybox-button--close::after {
  content: '';
  display: inline-block;
  position: absolute;
  height: 2px;
  width: 16px;
  top: calc(50% - 1px);
  left: calc(50% - 8px);
}

.fancybox-button--close::before {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.fancybox-button--close::after {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

/* Loading spinner */
.fancybox-loading {
  border: 6px solid rgba(100, 100, 100, 0.4);
  border-top: 6px solid rgba(255, 255, 255, 0.6);
  border-radius: 100%;
  height: 50px;
  width: 50px;
  -webkit-animation: fancybox-rotate .8s infinite linear;
  animation: fancybox-rotate .8s infinite linear;
  background: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -25px;
  margin-left: -25px;
  z-index: 99999;
}

@-webkit-keyframes fancybox-rotate {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes fancybox-rotate {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

/* Styling for Small-Screen Devices */
@media all and (max-width: 800px) {
  .fancybox-controls {
    text-align: left;
  }
  .fancybox-button--left,
  .fancybox-button--right,
  .fancybox-buttons button:not(.fancybox-button--close) {
    display: none !important;
  }
  .fancybox-caption {
    padding: 20px 0;
    margin: 0;
  }
}

/* Fullscreen  */
.fancybox-button--fullscreen::before {
  width: 15px;
  height: 11px;
  left: 15px;
  top: 16px;
  border: 2px solid;
  background: none;
}

/* Slideshow button */
.fancybox-button--play::before {
  top: 16px;
  left: 18px;
  width: 0;
  height: 0;
  border-top: 6px inset transparent;
  border-bottom: 6px inset transparent;
  border-left: 10px solid;
  border-radius: 1px;
  background: transparent;
}

.fancybox-button--pause::before {
  top: 16px;
  left: 18px;
  width: 7px;
  height: 11px;
  border-style: solid;
  border-width: 0 2px 0 2px;
  background: transparent;
}

/* Thumbs */
.fancybox-button--thumbs span {
  font-size: 23px;
}

.fancybox-button--thumbs::before {
  top: 20px;
  left: 21px;
  width: 3px;
  height: 3px;
  box-shadow: 0 -4px 0, -4px -4px 0, 4px -4px 0, 0 0 0 32px inset, -4px 0 0, 4px 0 0, 0 4px 0, -4px 4px 0, 4px 4px 0;
}

.fancybox-container--thumbs .fancybox-controls,
.fancybox-container--thumbs .fancybox-slider-wrap,
.fancybox-container--thumbs .fancybox-caption-wrap {
  right: 220px;
}

.fancybox-thumbs {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: auto;
  width: 220px;
  margin: 0;
  padding: 5px 5px 0 0;
  background: #fff;
  z-index: 99993;
  word-break: normal;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
}

.fancybox-thumbs > ul {
  list-style: none;
  position: absolute;
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
  font-size: 0;
}

.fancybox-thumbs > ul > li {
  float: left;
  overflow: hidden;
  max-width: 50%;
  padding: 0;
  margin: 0;
  width: 105px;
  height: 75px;
  position: relative;
  cursor: pointer;
  outline: none;
  border: 5px solid #fff;
  border-top-width: 0;
  border-right-width: 0;
  -webkit-tap-highlight-color: transparent;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  box-sizing: border-box;
}

li.fancybox-thumbs-loading {
  background: rgba(0, 0, 0, 0.1);
}

.fancybox-thumbs > ul > li > img {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  max-width: none;
  max-height: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.fancybox-thumbs > ul > li:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 2px;
  border: 4px solid #4ea7f9;
  z-index: 99991;
  opacity: 0;
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.fancybox-thumbs > ul > li.fancybox-thumbs-active:before {
  opacity: 1;
}

/* Styling for Small-Screen Devices */
@media all and (max-width: 800px) {
  .fancybox-thumbs {
    display: none !important;
  }
  .fancybox-container--thumbs .fancybox-controls,
  .fancybox-container--thumbs .fancybox-slider-wrap,
  .fancybox-container--thumbs .fancybox-caption-wrap {
    right: 0;
  }
}
